import { request } from '@/utils/request'

/**
 * 
 * @returns {登录页面接口}
 * 
 */
//登录
export function login(data) {
    return request({
        url: `/api/user/admin/login`,
        method: 'post',
        data
    })
}

//退出
export function exit() {
    return request({
        url: `/api/user/admin/logout`,
        method: 'post',
    })
}

//修改密码
export function resetpassword(data) {
    return request({
        url: `/api/user/user/resetPwd`,
        method: 'post',
        data
    })
}

/**
 * 
 * @param {公共资源数据} data 
 * @param {*} pagenum 
 * @param {*} size 
 * @returns 
 */

//城市列表
export function cityList() {
    return request({
        url: `/api/manage/related/city`,
        method: 'get',
    })
}
//公司列表
export function companyList(cityId) {
    return request({
        url: `/api/manage/related/company/${cityId}`,
        method: 'get',
    })
}

//品牌国家列表
export function countryList() {
    return request({
        url: `/api/manage/related/country`,
        method: 'get',
    })
}

//出资人列表
export function investorList() {
    return request({
        url: `/api/manage/related/investor`,
        method: 'get',
    })
}

/**
 * 
 * @returns {资产管理菜单接口}
 */

//资产菜单列表
export function propertyData(data, pagenum, size) {
    return request({
        url: `/api/manage/fixedAssets/areaList/${pagenum}/${size}`,
        method: 'post',
        data
    })
}



//导出固定资产excel
export function downPropertyList(data) {
    return request({
        url: `/api/manage/fixedAssets/exportExcel`,
        method: 'post',
        data,
        responseType: "blob", //服务器返回的数据类型
        timeout: 500 * 1000
    })
}

//新增固定资产
export function addFixedAssets(data) {
    return request({
        url: `/api/manage/fixedAssets/addFixedAssets`,
        method: 'post',
        data
    })
}

//修改固定资产信息
export function updateFixedAssets(data, id) {
    return request({
        url: `/api/manage/fixedAssets/updateFixedAssets/${id}`,
        method: 'put',
        data
    })
}

//删除固定资产信息
export function deleteFixedAssets(id) {
    return request({
        url: `/api/manage/fixedAssets/deleteFixedAssets/${id}`,
        method: 'delete',
    })
}

//通过id获取固定资产详细信息接口
export function FixedAssetsById(id) {
    console.log(id);
    return request({
        url: `/api/manage/fixedAssets/getFixedAssetsById/${id}`,
        method: 'get'
    })
}

//获取固定资产历史记录列表
export function fixedAssetsHistory(assetsId, pageSize, pageNum) {
    return request({
        url: `/api/manage/fixedAssetsHistory/areaList/${assetsId}/${pageSize}/${pageNum}`,
        method: 'get'
    })
}

//获取单条固定资产历史记录详细信息接口
export function fixedAssetsHistoryInfo(id) {
    return request({
        url: `/api/manage/fixedAssetsHistory/getFixedAssetsHistoryById/${id}`,
        method: 'get'
    })
}


//设置为最新版本
export function setToNew(id) {
    return request({
        url: `/api/manage/fixedAssetsHistory/setToNew/${id}`,
        method: 'post'
    })
}


/**
 * 
 * 
 * 租赁合同接口
 */

//租赁合同管理列表
export function leaseData(data, pagenum, size) {
    return request({
        url: `/api/manage/contract/areaList/${pagenum}/${size}`,
        method: 'post',
        data
    })
}

//新增合同资产接口
export function addleaseData(data) {
    return request({
        url: `/api/manage/contract/addContract`,
        method: 'post',
        data
    })
}
//删除合同信息

export function deleteLeaseDate(id) {
    return request({
        url: `/api/manage/contract/deleteContract/${id}`,
        method: 'delete'
    })
}
//编辑合同资产接口
export function updateleaseData(data, id) {
    return request({
        url: `/api/manage/contract/updateContract/${id}`,
        method: 'put',
        data
    })
}

//通过id获取合同详细信息接口
export function getidleaseData(id) {
    return request({
        url: `/api/manage/contract/getContractById/${id}`,
        method: 'get',
    })
}

//通过id获取合同历史记录列表
export function getcontractHistory(contractId) {
    return request({
        url: `/api/manage/contractHistory/areaList/${contractId}/1/20`,
        method: 'get',
    })
}

//获取单条合同历史记录详细信息息接口
export function getcontractSingle(id) {
    return request({
        url: `/api/manage/contractHistory/getContractHistoryById/${id}`,
        method: 'get',
    })
}

//设为最新版--当前历史版本id
export function updateIdNew(id) {
    return request({
        url: `/api/manage/contractHistory/setToNew/${id}`,
        method: 'post',
    })
}



// 下载租赁合同表格
export function downContract(data) {
    return request({
        url: `/api/manage/contract/exportExcel`,
        method: 'post',
        data,
        responseType: 'blob',//服务器返回的数据类型
        timeout: 500 * 1000
    })
}
