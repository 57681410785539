import axios from 'axios'
import { message } from '@/plugins/resetMessage.js'
import store from '@/store'

//引入nprogress 进度条插件
import NProgress from 'nprogress'
import { Loading } from 'element-ui'
// import { URL } from "@/api/baseUrl"
import router from "@/router/index"

let loading;
//开始显示loading遮罩层
function startLoading() {
    loading = Loading.service({
        lock: true,
        text: "加载中...",
        target: document.querySelector('.loaing-area')
    })
}
//关闭显示loading遮罩层
function endLoading() {
    loading.close()
}
/**计算多次请求,保证全部请求结束,再隐藏 */
let needLoadingRequestCount = 0;
function showFullScreenLoading() {
    if (needLoadingRequestCount === 0) {
        startLoading()
    }
    needLoadingRequestCount++
}
function tryHideFullScreenLoading() {
    if (needLoadingRequestCount <= 0) return;
    needLoadingRequestCount--;
    if (needLoadingRequestCount === 0) {
        endLoading()
    }
}




// 用于拦截code!=10000的响应数据
let errStatus = true;
export function request(config) {
    return new Promise((resolve, reject) => {
        var service = axios.create({
            baseURL: process.env.VUE_APP_URL,
            timeout: 30 * 1000,
        });


        // 添加请求拦截器
        service.interceptors.request.use(function (config) {
            // 开启进度条
            NProgress.start();
            showFullScreenLoading()
            // 在发送请求之前做些什么
            let token = window.sessionStorage.getItem('token')
            if (token) {
                config.headers.token = token
            }

            // config.headers['Content-Type'] = 'multipart/form-data';
            return config;

        }, function (error) {
            tryHideFullScreenLoading()
            // 对请求错误做些什么
            return Promise.reject(error);
        });



        // 添加响应拦截器
        service.interceptors.response.use(function (response) {
            // 关闭进度条
            NProgress.done()
            tryHideFullScreenLoading()

            // 对响应数据做点什么
            //判断--请求成功返回具体数据
            // console.log(response);
            const res = response.data
            if (res.code === 10000 || res instanceof Blob) {
                return res;
            } else if (res.code != 10000 && errStatus) {
                //10007	令牌到期失效
                if (res.code === 10007 || res.code == 10015 || res.code == 10002) {
                    errStatus = false  //修改状态,网络请求已经失败-防止多次网络请求造成重复弹窗
                    alert(res.message + "请重新登录")
                    window.sessionStorage.clear()
                    store.commit("clearTaglist");
                    router.push('/login')
                } else if (res.code === 10001) {
                    message({
                        message: res.message + '请根据提示信息检查表单!!' || 'Error',
                        type: 'error',
                        duration: 3 * 1000
                    })

                } else {
                    message({
                        message: res.message || 'Error',
                        type: 'error',
                        duration: 2 * 1000
                    })
                }
                let timeout = setTimeout(() => {
                    errStatus = true;
                    clearTimeout(timeout);
                }, 1500)



                return Promise.reject(res);

            }
        }, function (error) {
            tryHideFullScreenLoading()
            // 对响应错误做点什么
            return Promise.reject(error);
        });

        service(config).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}



